@font-face {
  font-family: "NanumSquare";
  font-weight: 400;
  font-display: auto;
  src: url(NanumSquareR.eot);
  src: url(NanumSquareR.eot?#iefix) format("embedded-opentype"),
    url(NanumSquareR.woff) format("woff"),
    url(NanumSquareR.ttf) format("truetype");
}
@font-face {
  font-family: "NanumSquare";
  font-weight: 700;
  font-display: auto;
  src: url(NanumSquareB.eot);
  src: url(NanumSquareB.eot?#iefix) format("embedded-opentype"),
    url(NanumSquareB.woff) format("woff"),
    url(NanumSquareB.ttf) format("truetype");
}
@font-face {
  font-family: "NanumSquare";
  font-weight: 800;
  font-display: auto;
  src: url(NanumSquareEB.eot);
  src: url(NanumSquareEB.eot?#iefix) format("embedded-opentype"),
    url(NanumSquareEB.woff) format("woff"),
    url(NanumSquareEB.ttf) format("truetype");
}
@font-face {
  font-family: "NanumSquare";
  font-weight: 300;
  font-display: auto;
  src: url(NanumSquareL.eot);
  src: url(NanumSquareL.eot?#iefix) format("embedded-opentype"),
    url(NanumSquareL.woff) format("woff"),
    url(NanumSquareL.ttf) format("truetype");
}
