@import url(https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: 200;
  font-display: auto;
  src: url(/static/media/SpoqaHanSansNeo-Thin.adbf2954.otf) format("otf"),
    url(/static/media/SpoqaHanSansNeo-Thin.95a4d909.woff) format("woff"),
    url(/static/media/SpoqaHanSansNeo-Thin.601b1a1a.ttf) format("truetype");
}

@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: 300;
  font-display: auto;
  src: url(/static/media/SpoqaHanSansNeo-Light.a9a30257.otf) format("otf"),
    url(/static/media/SpoqaHanSansNeo-Light.27f2df7a.woff) format("woff"),
    url(/static/media/SpoqaHanSansNeo-Light.271ed820.ttf) format("truetype");
}

@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: 400;
  font-display: auto;
  src: url(/static/media/SpoqaHanSansNeo-Regular.eca2b2af.otf) format("otf"),
    url(/static/media/SpoqaHanSansNeo-Regular.f864a34f.woff) format("woff"),
    url(/static/media/SpoqaHanSansNeo-Regular.b441ad42.ttf) format("truetype");
}

@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: 500;
  font-display: auto;
  src: url(/static/media/SpoqaHanSansNeo-Medium.eb30c62e.otf) format("otf"),
    url(/static/media/SpoqaHanSansNeo-Medium.0c714afe.woff) format("woff"),
    url(/static/media/SpoqaHanSansNeo-Medium.07502c6b.ttf) format("truetype");
}

@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: 600;
  font-display: auto;
  src: url(/static/media/SpoqaHanSansNeo-Bold.c233f0d6.otf) format("otf"),
    url(/static/media/SpoqaHanSansNeo-Bold.3bc5bf55.woff) format("woff"),
    url(/static/media/SpoqaHanSansNeo-Bold.130fce4c.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSansCJKkr";
  font-weight: 200;
  font-display: auto;
  src: url(/static/media/NotoSansCJKkr-Thin.c5e62551.otf) format("otf"),
    url(/static/media/NotoSansCJKkr-Thin.e176b220.woff) format("woff"),
    url(/static/media/NotoSansCJKkr-Thin.edbfe3a4.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSansCJKkr";
  font-weight: 300;
  font-display: auto;
  src: url(/static/media/NotoSansCJKkr-Light.528e085c.otf) format("otf"),
    url(/static/media/NotoSansCJKkr-Light.e10feff6.woff) format("woff"),
    url(/static/media/NotoSansCJKkr-Light.36a81d1e.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSansCJKkr";
  font-weight: 400;
  font-display: auto;
  src: url(/static/media/NotoSansCJKkr-DemiLight.0d80d754.otf) format("otf"),
    url(/static/media/NotoSansCJKkr-DemiLight.3bd6fb70.woff) format("woff"),
    url(/static/media/NotoSansCJKkr-DemiLight.158dda4c.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSansCJKkr";
  font-weight: 500;
  font-display: auto;
  src: url(/static/media/NotoSansCJKkr-Regular.3e8a3d88.otf) format("otf"),
    url(/static/media/NotoSansCJKkr-Regular.b792e9c7.woff) format("woff"),
    url(/static/media/NotoSansCJKkr-Regular.3160fe2f.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSansCJKkr";
  font-weight: 600;
  font-display: auto;
  src: url(/static/media/NotoSansCJKkr-Medium.a89c0e76.otf) format("otf"),
    url(/static/media/NotoSansCJKkr-Medium.a4305cb3.woff) format("woff"),
    url(/static/media/NotoSansCJKkr-Medium.6a118136.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSansCJKkr";
  font-weight: 700;
  font-display: auto;
  src: url(/static/media/NotoSansCJKkr-Bold.9b1db29f.otf) format("otf"),
    url(/static/media/NotoSansCJKkr-Bold.e9287657.woff) format("woff"),
    url(/static/media/NotoSansCJKkr-Bold.646e4df4.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSansCJKkr";
  font-weight: 800;
  font-display: auto;
  src: url(/static/media/NotoSansCJKkr-Black.c08d1a40.otf) format("otf"),
    url(/static/media/NotoSansCJKkr-Black.8460282a.woff) format("woff"),
    url(/static/media/NotoSansCJKkr-Black.b1a4d85c.ttf) format("truetype");
}

@font-face {
  font-family: "NanumSquare";
  font-weight: 400;
  font-display: auto;
  src: url(/static/media/NanumSquareR.a113ef7b.eot);
  src: url(/static/media/NanumSquareR.a113ef7b.eot?#iefix) format("embedded-opentype"),
    url(/static/media/NanumSquareR.94ee92ff.woff) format("woff"),
    url(/static/media/NanumSquareR.924c33f6.ttf) format("truetype");
}
@font-face {
  font-family: "NanumSquare";
  font-weight: 700;
  font-display: auto;
  src: url(/static/media/NanumSquareB.6e5f5d75.eot);
  src: url(/static/media/NanumSquareB.6e5f5d75.eot?#iefix) format("embedded-opentype"),
    url(/static/media/NanumSquareB.5d53333f.woff) format("woff"),
    url(/static/media/NanumSquareB.ebe0aa17.ttf) format("truetype");
}
@font-face {
  font-family: "NanumSquare";
  font-weight: 800;
  font-display: auto;
  src: url(/static/media/NanumSquareEB.87e8b5f9.eot);
  src: url(/static/media/NanumSquareEB.87e8b5f9.eot?#iefix) format("embedded-opentype"),
    url(/static/media/NanumSquareEB.7f267188.woff) format("woff"),
    url(/static/media/NanumSquareEB.e5471443.ttf) format("truetype");
}
@font-face {
  font-family: "NanumSquare";
  font-weight: 300;
  font-display: auto;
  src: url(/static/media/NanumSquareL.5c6a24ab.eot);
  src: url(/static/media/NanumSquareL.5c6a24ab.eot?#iefix) format("embedded-opentype"),
    url(/static/media/NanumSquareL.3f3fd18b.woff) format("woff"),
    url(/static/media/NanumSquareL.ace03365.ttf) format("truetype");
}

.App {
  text-align: center;
  font-family: 'NanumSquareRound';
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

